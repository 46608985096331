<template>
  <v-layout class="mb-4 pb-4" wrap align-start row>
    <v-flex xs12>
      <Title title="Configuracion Web" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-layout class="py-2 px-2 border-bottom" wrap align-start row>
            <v-flex xs6 class="body-2">
              Imagen de fondo - Web
            </v-flex>
            <v-flex xs6>
              <v-img
                v-if="bg_webEmpty === false && data.bg_web !== ''"
                :max-width="'60%'"
                class="mb-2"
                :src="data.bg_web"
              />
              <v-img v-if="bg_webEmpty === true && bg_web !== ''" :max-width="'60%'" class="mb-2" :src="bg_web" />
              <FileReader
                v-if="bg_webEmpty === true"
                :name="`${bg_web !== '' ? 'Cambiar Foto' : 'Subir Foto'}`"
                :flat="false"
                :get-file="getFile"
                @load="bg_web = $event"
              ></FileReader>
              <p v-if="bg_webEmpty === true" class="caption mt-2">La imagen debe ser 1964 x 1080</p>
              <v-btn v-if="bg_webEmpty === false" color="error" block @click.native="bg_webEmpty = true">
                Quitar Foto
                <v-icon right dark>cloud_upload</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs6 class="body-2">
              Imagen de fondo - Web Mobile
            </v-flex>
            <v-flex xs6>
              <v-img
                v-if="bg_web_mobileEmpty === false && data.bg_web_mobile !== ''"
                class="mb-2"
                :max-width="'60%'"
                :src="data.bg_web_mobile"
              />
              <v-img
                v-if="bg_web_mobileEmpty === true && bg_web_mobile !== ''"
                :max-width="'60%'"
                class="mb-2"
                :src="bg_web_mobile"
              />
              <FileReader
                v-if="bg_web_mobileEmpty === true"
                :name="`${bg_web !== '' ? 'Cambiar Foto' : 'Subir Foto'}`"
                :flat="false"
                :get-file="getFileMobile"
                @load="bg_web_mobile = $event"
              ></FileReader>
              <p v-if="bg_web_mobileEmpty === true" class="caption mt-2">La imagen debe ser 640 x 960</p>
              <v-btn v-if="bg_web_mobileEmpty === false" color="error" block @click.native="bg_web_mobileEmpty = true">
                Quitar Foto
                <v-icon right dark>cloud_upload</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="update">
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Title from '../useful/title.vue'
import { MI_STORE } from '../../config'
import FileReader from '../useful/FileReader.vue'
export default {
  name: 'SettingGeneral',
  components: {
    Title,
    FileReader
  },
  props: ['config', 'user'],
  data() {
    return {
      data: {},
      loading: false,
      dateContest: null,
      activeContest: false,
      productID: false,
      modal: false,
      closePhoto: '',
      emptyPhoto: null,
      bg_web: '',
      bg_web_mobile: '',
      bg_web_input: null,
      bg_web_mobile_input: null,
      bg_webEmpty: null,
      bg_web_mobileEmpty: null
    }
  },
  computed: {},
  mounted() {
    this.getShop()
  },
  methods: {
    async getShop() {
      try {
        const res = await this.$http.get(`${MI_STORE}/shop-all`)
        const data = res.data
        this.data = data
        if (data.bg_web) {
          this.bg_webEmpty = false
        }
        if (data.bg_web_mobile) {
          this.bg_web_mobileEmpty = false
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async update() {
      try {
        const formData = new FormData()
        formData.append('bg_web', this.bg_web_input)
        formData.append('bg_web_mobile', this.bg_web_mobile_input)
        const res = await this.$http.post(`${MI_STORE}/shop-all`, formData)
        console.log(res)
        this.getShop()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    getFile(file) {
      const size = 1572864
      if (file.size > size) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'La imagen deben ser menor a 1.5mb'
        })
        return
      }
      this.bg_web_input = file
    },
    getFileMobile(file) {
      const size = 1572864
      if (file.size > size) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'La imagen deben ser menor a 1.5mb'
        })
        return
      }
      this.bg_web_mobile_input = file
    }
  }
}
</script>
